<div class="conn-card">
    <app-report-top-filter-bar *ngIf="1==1" [paraReportName]="'credit-card-summary-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)" (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()" (exportPdfFn)="exportPdf()" (exportCsvFn)="dt.exportCSV()">
    </app-report-top-filter-bar>
    <br />
    <div *ngFor="let data of dataList; let i = index">
        <span class="location-title mb-2">
           {{data.ccDetails[0].locationName }}
           <!-- {{data.ccDetails[i].locationName }} -->
        </span>
         
           <div class="grid mt-2" >
         
            <div class="col">
                <div class="conn-card" style="background:#f5f8fa">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                    CC Sale
                                </label>
                            </div>
                            <div>
                                <label class="widiget-figure-main">
                                    {{data.creditCardTotal | currency}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="conn-card" style="background:#f5f8fa">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                    CC Refunds
                                </label>
                            </div>
                            <div >
                                <label class="widiget-figure-main">
                                    {{data.returnsTotal | currency}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="conn-card" style="background:#f5f8fa">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                    Tip Amount
                                </label>
                            </div>
                            <div >
                                <label class="widiget-figure-main">
                                    {{data.tipAmount | currency}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">

                <div class="conn-card" style="background:var(--brandColor);color:white">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                    Expected CC Deposit
                                </label>
                            </div>
                            <div>
                                <label class="widiget-figure-main">
                                    {{ calculateSummaryTotalNoFormating("creditCardTotal")-calculateSummaryTotalNoFormating("returnsTotal")|currency}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="col">
                <div class="conn-card" style="background:var(--brandColor);color:white">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                Fee Collected
                                </label>
                            </div>
                            <div >
                                <label class="widiget-figure-main">
                                    {{data.feesTotal | currency}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     

            <div class="col-4">

                <div class="conn-card" style="background:#f5f8fa">
                    <div class="grid">
                        <div class="col-12 text-align-center">
                            <div>
                                <label class="widget-title">
                                    Expected Net Deposit : {{(data.creditCardTotal-data.returnsTotal)|currency}}
                                    <!-- Expected Deposit (CC Fee not included) : {{(data.creditCardTotal-data.returnsTotal)|currency}} -->
                                </label>
                            </div>
                            <div>
                                <label class="widget-title" style="border-bottom: none;">
                                    Expected Gross Deposit : {{(data.creditCardTotal+data.feesTotal)-data.returnsTotal|currency}}
                                    <!-- Expected Deposit (CC Fee included) : {{(data.creditCardTotal+data.feesTotal)-data.returnsTotal|currency}} -->
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p-table [value]="data.ccDetails" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped"
            [scrollable]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center" *ngFor="let col of domColHeader">
                        <strong>{{col}}</strong>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded">
                <tr >
                    <td style="text-align: center">{{ product.locationName }}</td>
                    <td style="text-align: center">
                       
                        <!-- <span><img [src]="'assets/images/'+product?.type.toLowerCase() +'_icon.png'" style="width: 25px;margin-right:5px;"> </span> -->
                       
                       
                        <!-- <span *ngIf="order.type == 'MASTERCARD'"><img src="assets/images/mastercard_icon.png"
                                style="width: 25px" /></span>
                        <span *ngIf="order.type == 'VISA'"><img src="assets/images/visa_icon.png"
                                style="width: 25px" /></span>
                        <span *ngIf="order.type == 'AMEX'"><img src="assets/images/AMX_ICON.png"
                                style="width: 25px" /></span>
                        <span *ngIf="order.type == 'DISCOVER'"><img src="assets/images/Discover-icon.png"
                                style="width: 25px" /></span>
                        <span *ngIf="order.type == 'OTHER'"><img src="assets/images/other_icon.png"
                                style="width: 25px" /></span> -->
                        {{ product.type }}
                    </td>
                    <td style="text-align: center">{{ product.invoiceCount }}</td>
                    <td style="text-align: center">{{ product.creditCardTotal | currency }} </td>
                    <td style="text-align: center">{{ product.returnsTotal | currency }}</td>
                    <td style="text-align: center">{{ product.voidsTotal | currency }}</td>
                    <td style="text-align: center">{{ product.feesTotal | currency }}</td>
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="rowexpansion" let-product>
                <tr>
                    <td colspan="12">
                        <div class="p-p-3">
                            <p-table [value]="product.ccDetails" dataKey="id">
                                <ng-template pTemplate="header">
                                    <tr>

                                        <th>Type</th>
                                        <th style="text-align: center">Invoice Count</th>
                                        <th style="text-align: center">CC Total ($)</th>
                                        <th style="text-align: center">Return ($)</th>
                                        <th style="text-align: center">Void ($)</th>
                                        <th style="text-align: center"># Invoice Total ($)</th>
                                        <th style="text-align: right">Fees Total ($)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-order>
                                    <tr>
                                        <td>{{ order.type }}</td>
                                        <td style="text-align: center">{{ order.invoiceCount }}</td>
                                        <td style="text-align: center">{{ order.creditCardTotal | currency }} </td>
                                        <td style="text-align: center">{{ order.returnsTotal | currency }}</td>
                                        <td style="text-align: center">{{ order.voidsTotal | currency }}</td>
                                        <td style="text-align: center">{{ order.invoicesTotal | currency }}</td>
                                        <td style="text-align: right">{{ order.feesTotal | currency }}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6">
                                            There is no data to show.
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template> -->
            <ng-template pTemplate="footer">
                <tr *ngIf="dataList?.length > 0">
                    <td colspan="2" style="text-align: center">
                        <strong>Total</strong>
                    </td>
                    <td></td>
                    <td *ngFor="let col of domSummaryflds" style="text-align: center">
                        {{calculateSummaryTotal(col)}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br *ngIf="dataList?.length !==0 " />
        <br *ngIf="dataList?.length !==0 " />
    </div>
    
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
