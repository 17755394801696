import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LoyaltyConfig, PointsDateTime } from 'src/app/_models/reward-program/loyalty-configuration';
import { PointsExpire } from 'src/app/_models/reward-program/point-expire';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';

interface ExpiryDuration {
    name: string,
    title: string
}

interface dayModel {
    name: string,
    title: string
}

interface CheckBoxOptions {
    title: string;
    active: boolean;
}


@Component({
  selector: 'app-loyalty-program-general',
  templateUrl: './loyalty-program-general.component.html',
  styleUrls: ['./loyalty-program-general.component.scss']
})
export class LoyaltyProgramGeneralComponent implements OnInit, AfterViewInit {
    
    _globals = GlobalService

    enableLoyaltyProgram: boolean = true;
    expiryDurationList: ExpiryDuration[];
    loyaltyConfiguration!: FormGroup;
    selectedExpiry: ExpiryDuration;
    InputDiscountOnItem: boolean = true;
    InputDiscountOpenDepart: boolean = false;
    selectedValue: string;
    daysList: dayModel[];
    selectedDays: dayModel[];
    date8: Date;
    InputCashierAlert: boolean = false;
    cashierAlerts: CheckBoxOptions[] = [];
    sendEmailWhenPointsRedeemed: boolean = false;
    sendEmailWhenPointsAccumulated: boolean = false;
    InputPrintPointsOnReceipt: boolean = false;
    InputDisplayPointsOnSC: boolean = false;
    InputSMSNotification: boolean = false;
    InputEmailNotification: boolean = false;
    printInfoList: CheckBoxOptions[] = [];
    smsNotificationList: CheckBoxOptions[] = [];
    loyaltyObj = <LoyaltyConfig>{};
    showAccordion: boolean = true;
    isExtraRewardInfoOnReceipt: boolean = false;

    constructor(
        private fb: FormBuilder,
        public _abd: AppBaseDataService,
        @Inject(DOCUMENT) document: Document,
        public _rewardProgramService: RewardProgramService,
        private _messageService: MessageService) { }
    ngAfterViewInit(): void {
        const imgButton = document.getElementsByClassName('ql-image');

        for (let i = 0; i < imgButton.length; i++) {
            if (i > 1){
                imgButton[i].remove();
            }
        }
    }

    ngOnInit(): void {
        this.expiryDurationList = [
            { name: 'Never', title: 'Never' },
            { name: 'EveryWeek', title: 'Weekly' },
            { name: 'EveryMonth', title: 'Monthly' },
            { name: 'Yearly', title: 'Yearly' },
        ];
  
        this.daysList = [
            { name: 'Sunday', title: 'Sunday' },
            { name: 'Monday', title: 'Monday' },
            { name: 'Tuesday', title: 'Tuesday' },
            { name: 'Wednesday', title: 'Wednesday' },
            { name: 'Thursday', title: 'Thursday' },
            { name: 'Friday', title: 'Friday' },
            { name: 'Saturday', title: 'Saturday' },
        ];

        this.selectedDays = []
        this.date8 = new Date();

        this.cashierAlerts = [
            { title: 'Display the "Reward Redemption" prompt on the cashier screen when the designed points goal is reached.', active: false },
            { title: 'Trigger a "Sound Alert" for the cashier when the designed points goal is reached', active: false },
        ];
    

        //  { title: 'Reward Perk Information', active: true },

        this.printInfoList = [
            { title: 'Customer First Name', active: true },
            { title: 'Customer Last Name', active: true },
            { title: 'Customer Phone', active: true },
            { title: 'Customer Email', active: true },
            { title: 'Customer ID', active: true },
            { title: 'Current Loyalty Points', active: true },

        ];

        this.smsNotificationList = [
            { title: 'Send SMS when points are redeemed', active: false },
            { title: 'Send SMS when points are accumulated', active: false },
        ];


        this.loyaltyConfiguration = this.fb.group({
            dollarValue: [0, Validators.required],
            pointsAccThreshold: [0, Validators.required],
            maxPointsAllowed: [0, Validators.required],
            subject: [''],
            message: [''],
            pointsAccumulateEmailSubject: [''],
            pointsAccumulateEmailBody: [''],
            extraRewardInfoOnReceipt: [''],
        });

        this.showAccordion = true;
      
        this.isExtraRewardInfoOnReceipt = false;
        if (this._globals.userInfo.businessSetup[0].enableLoyaltyReward){
            this.getGeneralLoyaltyConfiguration();
        }

    }

    getGeneralLoyaltyConfiguration() {
        try {
            this._abd.showSpinner()
            this._rewardProgramService.getRegularDiscount(false).subscribe((r) => {
                if (r.success) {
                    this.loyaltyObj = r.data;
                    // this.customerLoyaltyObj = r.data;

                    this.enableLoyaltyProgram = this.loyaltyObj.active;
                    this.showAccordion = this.enableLoyaltyProgram;
                    this.f.dollarValue.setValue(this.loyaltyObj.minSpendAmountReward);
                    this.f.pointsAccThreshold.setValue(this.loyaltyObj.minCartValue);
                    this.f.maxPointsAllowed.setValue(this.loyaltyObj.maxAllowedPoints);
                    this.InputCashierAlert = this.loyaltyObj.cashierAlert;
                    this.cashierAlerts[0].active = this.loyaltyObj.cashierPromptRewardRedemptionOnPointsGoalReached ;
                    this.cashierAlerts[1].active = this.loyaltyObj.cashierSoundAlert ;
                    this.InputPrintPointsOnReceipt = this.loyaltyObj.receiptPrintPointInfo  ;
                    this.printInfoList[0].active = this.loyaltyObj.receiptPrintCustomerFName  ;
                    this.printInfoList[1].active = this.loyaltyObj.receiptPrintCustomerLName ;
                    this.printInfoList[2].active = this.loyaltyObj.receiptPrintCustomerPhone ;
                    this.printInfoList[3].active = this.loyaltyObj.receiptPrintCustomerEmail ;
                    this.printInfoList[4].active = this.loyaltyObj.receiptPrintCustomerId  ;
                    this.printInfoList[3].active = this.loyaltyObj.receiptPrintCurrentPoints ;
                    //  this.printInfoList[6].active = this.loyaltyObj.receiptPrintRerkInfo ;
                    this.InputDisplayPointsOnSC = this.loyaltyObj.displayPointsOnSecondaryScreen ;
                    this.InputSMSNotification = this.loyaltyObj.smsNotificationToCustomers;
                    this.smsNotificationList[0].active = this.loyaltyObj.sendSMSOnPointsRedeemed  ;
                    this.smsNotificationList[1].active = this.loyaltyObj.sendSMSOnPointsAccumulated ;
                    this.InputEmailNotification = this.loyaltyObj.emailNotificationToCustomers;
                    this.sendEmailWhenPointsRedeemed = this.loyaltyObj.sendEmailOnPointsRedeem ;
                    this.f.subject.setValue(this.loyaltyObj.pointsRedeemEmailSubject);
                    this.f.message.setValue(this.loyaltyObj.pointsRedeemEmailBody);
                    this.f.pointsAccumulateEmailSubject.setValue(this.loyaltyObj.pointsAccumulateEmailSubject);
                    this.f.pointsAccumulateEmailBody.setValue(this.loyaltyObj.pointsAccumulateEmailBody);
                    this.sendEmailWhenPointsAccumulated = this.loyaltyObj.sendEmailOnPointsAccumulate ;
                    this.selectedExpiry = this.expiryDurationList.filter(f => f.name == this.loyaltyObj.pointsExpireString)[0]
                    this.InputDiscountOnItem = this.loyaltyObj.pointsAccumulationOnDiscountedItems ;
                    this.InputDiscountOpenDepart = this.loyaltyObj.pointsAccumulationOnOpenDeptItems ;
                    this.f.extraRewardInfoOnReceipt.setValue(this.loyaltyObj.extraRewardInfoOnReceipt);
                    this.isExtraRewardInfoOnReceipt = this.loyaltyObj.isExtraRewardInfoOnReceipt;

                 
                      let self = this;
                    this.selectedDays = [];
                    this.loyaltyObj.pointsDateTime.map(function(value){
                       if(self.daysList.filter(f=> f.name == value.day).length > 0){
                           self.selectedDays.push({ name: value.day, title: value.day })
                       }
                    })


                    this._abd.hideSpinner()
                }
                else {
                    this._abd.hideSpinner()
                }
            }, error => {
                this._messageService.add({ severity: 'error', summary: 'Unable to load configuration ' })
                this._abd.hideSpinner()
            })
        } catch (error) {

        }
    }

    saveInformationLoyalty(){
        if (this.loyaltyConfiguration.invalid) {
            // Got focus to the error field
            // let invalidFields = [].slice.call(
            //     document.getElementsByClassName('ng-invalid')
            // );
            // invalidFields[1].focus();
        }

        if (this.InputCashierAlert){
            if (this.cashierAlerts.filter(f => f.active).length == 0){
                // this._messageService.add({ severity: 'error', summary: 'Please select any option in `Cashier alert` under Points Redemption' })
                this._messageService.add({ severity: 'error', summary: 'Please select any option in `Cashier alert` under Points Redemption' })
                return false;
            }
        }

        // if (this.InputPrintPointsOnReceipt) {
        //     if (this.printInfoList.filter(f => f.active).length == 0) {
        //         this._messageService.add({ severity: 'error', summary: 'Please select any option in `Print points info on the receipt` under Printing & Interaction' })
        //         return false;
        //     }
        // }

        if (this.InputSMSNotification) {
            if (this.smsNotificationList.filter(f => f.active).length == 0) {
                // this._messageService.add({ severity: 'error', summary: 'Please select any option in `SMS notification to customers` under Printing & Interaction' })
                this._messageService.add({ severity: 'error', summary: 'Please select any option in `SMS notification to customers` under Printing & Interaction' })
                return false;
            }
        }

        if (this.InputEmailNotification) {
            if (!this.sendEmailWhenPointsRedeemed && !this.sendEmailWhenPointsAccumulated) {
                this._messageService.add({ severity: 'error', summary: 'Please select at least one option under `Email notification to customer`' })
                return false;
            }
        }
  
        let obj = <LoyaltyConfig>{};
        obj.active = this.enableLoyaltyProgram;
        obj.minSpendAmountReward = this.f.dollarValue.value;
        obj.minCartValue = this.f.pointsAccThreshold.value;
        obj.minSpendAmountForReward = 1 //this.f.pointsAccThreshold.value;
        obj.maxAllowedPoints = this.f.maxPointsAllowed.value;    
        obj.pointsAccumulationOnDiscountedItems = this.InputDiscountOnItem;
        obj.pointsAccumulationOnOpenDeptItems = this.InputDiscountOpenDepart;
        obj.pointsExpire = this.setExpiryValue();
        obj.pointsDateTime = this.setRewardDayAndTime();
        obj.cashierAlert = this.InputCashierAlert;
        obj.cashierPromptRewardRedemptionOnPointsGoalReached = this.cashierAlerts[0].active;
        obj.cashierSoundAlert = this.cashierAlerts[1].active;
        obj.receiptPrintPointInfo = this.InputPrintPointsOnReceipt;
        obj.receiptPrintCustomerFName = this.InputPrintPointsOnReceipt //this.printInfoList[0].active //this.printInfoList[0].active;
        obj.receiptPrintCustomerLName =this.InputPrintPointsOnReceipt // this.printInfoList[0].active //this.printInfoList[1].active;
        obj.receiptPrintCustomerPhone = this.InputPrintPointsOnReceipt //this.printInfoList[0].active //this.printInfoList[2].active;
        obj.receiptPrintCustomerEmail = this.InputPrintPointsOnReceipt // this.printInfoList[3].active;
        obj.receiptPrintCustomerId = this.InputPrintPointsOnReceipt // this.printInfoList[4].active;
        obj.receiptPrintCurrentPoints = this.InputPrintPointsOnReceipt // this.printInfoList[0].active //this.printInfoList[3].active;
       // obj.receiptPrintRerkInfo = this.printInfoList[6].active;
        obj.displayPointsOnSecondaryScreen = this.InputDisplayPointsOnSC;
        obj.smsNotificationToCustomers = false //this.InputSMSNotification;
        obj.sendSMSOnPointsRedeemed = false //this.smsNotificationList[0].active;
        obj.sendSMSOnPointsAccumulated = false //this.smsNotificationList[1].active;
        obj.emailNotificationToCustomers = this.InputEmailNotification;
        obj.sendEmailOnPointsRedeem = this.sendEmailWhenPointsRedeemed;
        obj.pointsRedeemEmailSubject = this.f.subject.value;
        obj.pointsRedeemEmailBody = this.f.message.value;
        obj.pointsAccumulateEmailSubject = this.f.pointsAccumulateEmailSubject.value;
        obj.pointsAccumulateEmailBody = this.f.pointsAccumulateEmailBody.value;
        obj.sendEmailOnPointsAccumulate = this.sendEmailWhenPointsAccumulated;
        obj.isExtraRewardInfoOnReceipt = this.isExtraRewardInfoOnReceipt;
        obj.extraRewardInfoOnReceipt = this.f.extraRewardInfoOnReceipt.value;
        
        this._abd.showSpinner()
        this._rewardProgramService.saveGeneralRewardConfiguration(obj).subscribe((r)=>{
            if(r.success){
                this._abd.hideSpinner()
                this._messageService.add({ severity: 'success', summary: 'Saved successfully' })
            }
            else{
                this._abd.hideSpinner()
                this._messageService.add({ severity: 'error', summary: 'Unable to save data' })
            }
        }, error=>{
            this._abd.hideSpinner()
            this._messageService.add({ severity: 'error', summary: 'Unable to save data' })
        })


    }

    setExpiryValue(){
        try {
            let expiry = <PointsExpire>{};

            switch (this.selectedExpiry.name) {
                case 'Never':
                    expiry = PointsExpire.Never
                    break;
                case 'EveryWeek':
                    expiry = PointsExpire.EveryWeek
                    break;

                case 'EveryMonth':
                    expiry = PointsExpire.EveryMonth
                    break;
                case 'Yearly':
                    expiry = PointsExpire.Yearly
                    break;

                default:
                    expiry = PointsExpire.Never
                    break;
            }

            return expiry;
        } catch (error) {

        }
    }

    setRewardDayAndTime(){
        try {

            var end = new Date();
            end.setUTCHours(23, 59, 59, 999);
            let dayAndTimeList: PointsDateTime[] = [];
            this.selectedDays.map(function(value){
                dayAndTimeList.push({
                    loyaltyConfigId:"",
                    day: value.name,
                    dateTime: end.toLocaleDateString('en-US') + " 23:59:59",
                    type: "Redemption"
                })
            })
            return dayAndTimeList;
        } catch (error) {

        }
    }

    get f() { return this.loyaltyConfiguration.controls; }
}
