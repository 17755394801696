import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditTrialService {
  filter: any = null

  activityType:any[] =[
    {value:'All'},
    {value:'Add'},
    {value:'Modify'},
    {value:'Transaction'},
    {value:'Delete'},
  ]
  operationType:any[] =[
    {value:'All',name:'All'},
    {value:'Item',name:'Product'},
    {value:'Discount',name:'Discount'},
    {value:'Customer',name:'Customer'},
    {value:'Categories',name:'Categories'},
    {value:'Department',name:'Department'},
    {value:'Vendor',name:'Vendor'},
    {value:'Manufacturer',name:'Manufacturer'},
    {value:'LoyaltyConfigs',name:'Loyalty Configuration'},
    {value:'PerksReward',name:'Perks Reward'},
    {value:'BarCodeQue',name:'Bar Codes'},
    {value:'Inventory',name:'Inventory'},
    {value:'PurchaseOrder',name:'Purchase Order'},
    {value:'LabelPrinting',name:'Label Printing'},
    {value:'Reconcilation'},
    {value:'Settings',name:'Settings'},
  ]

  selectedAudit:any = null;

  constructor(
    private _bs: BaseService,
  ) {
    this.filter = {
      searchText: '',
      selectedLocation: '',
    }
  }


  getAuditTrialSummaryList(obj?:any) {
    let url = environment.BusinessBaseURL + 'audit/logs?Type=summary';

    return this._bs.httpPost(url, obj).pipe(map(res => {
      return res
    }))
  }
  getAuditTrialHistoryList(obj?: any) {
    let url = environment.BusinessBaseURL + 'audit/logs?Type=history';

    return this._bs.httpPost(url,obj).pipe(map(res => {
      return res
    }))
  }


}
